import React from 'react'
import styles from './upcomingScreen.module.scss'
import { SetShowEvent, SetShowForm } from '../../state/reducers/propertySlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../state/store';

interface UpcomingEventProp {
    closeModal: () => void;
    showevent: boolean
}

const UpcomingEvent: React.FC<UpcomingEventProp> = ({closeModal, showevent}) => {
  const dispatch = useDispatch<AppDispatch>()
  return (
    <section style={{alignItems: 'center', justifyContent: 'center', padding: 10}}>
    <div
      style={{
        display: "flex",
        width: "100%",
        alignItems: "left",
        justifyContent: "space-between",
        marginTop: 30,
      }}
    >
      <div
        style={{
          marginLeft: "35%",
          alignSelf: 'center'
        }}
      >
        <b style={{fontSize: 20, color: '#0D600D'}}>Let's Talk Real Estate!</b>
      </div>
      <div className={styles.clickableelement} >
        <div className="icon-cancel" onClick={closeModal}></div>
        </div>
        </div>
        <div style={{alignSelf: 'center', justifyContent: 'center'}}>
           <div style={{height: '7%', width: '100%'}}>
             <p style={{marginTop: 10, fontSize: 14, letterSpacing: 1, textAlign: 'justify', color: 'black'}}>
            We are thrilled to announce an upcoming Homebuddy Brunch session, a unique gathering designed exclusively for real estate professionals (Agents and Landlords). This event will offer a delicious brunch paired with meaningful conversations about how Homebuddy can revolutionize the way landlords and agents support their clients. Join us to explore how our platform simplifies home management, creates value for homeowners and agents, and enhances service offerings. It's the perfect opportunity to network with peers, share insights and discover tools that can elevate your business.
           <p style={{textAlign: 'center', marginTop: 4}}>Click the button below to register</p> 
            </p>
        <button type="button" style={{ backgroundColor: '#0D600D',color: 'white', width: 164, height: 49, borderRadius: 8, alignSelf: 'center', marginLeft: '40%'}} onClick={()=>{
          dispatch(SetShowEvent(false));
          dispatch(SetShowForm(true));
        }}>Register</button>
            </div>
        </div>
  </section>
  )
}

export default UpcomingEvent