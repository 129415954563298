import React, { useState } from "react";
import styles from "./upcomingScreen.module.scss";
import { EventReq, EventSource, EventType } from "../../types/Property";
import { toast } from "react-toastify";
import { AddEventApi } from "../../swr/properties";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../state/store";
import { SetShowForm } from "../../state/reducers/propertySlice";

interface EventFormProp {
  closeModal: () => void;
  showform: boolean;
}

const EventForm: React.FC<EventFormProp> = ({ closeModal, showform }) => {
    const dispatch = useDispatch<AppDispatch>();
  const [eventForm, setEventForm] = useState<EventReq>({
    email: "",
    phoneNumber: "",
    fullName: "",
    company: "",
    designation: "",
    eventUserType: 0 as EventType,
    otherEventUserType: "",
    onAnyPlatform: false,
    otherPlatform: "",
    challenges: "",
    eventSource: 0 as EventSource,
    otherEventSource: "",
    mailingList: true,
  });

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;

    setEventForm((prev) => ({
      ...prev,
      [name]: handleValue(name, value),
    }));
  };

  const handleValue = (name: string, value: string) => {
    // Handle booleans (e.g., "Yes" => true, "No" => false, "true" => true, "false" => false)
    if (value === "Yes" || value === "true") return true;
    if (value === "No" || value === "false") return false;
  
    // Handle enums (e.g., EventType or EventSource)
    if (name === "eventUserType" || name === "eventSource") {
      return parseInt(value, 10); // Convert the value to a number
    }
  
    // Handle strings by default
    return value;
  };
  

  console.log(eventForm, 'here is the data')
  const HandleFormSubmission = async () => {
  console.log(eventForm, 'here is the data inside')

    const {
      fullName,
      email,
      eventSource,
      eventUserType,
      phoneNumber,
      challenges,
      company,
      designation,
    } = eventForm;

    if (
      !fullName ||
      !email ||
      !phoneNumber ||
      !eventSource ||
      !challenges ||
      !company ||
      !eventUserType ||
      !designation 
    ) {
      toast.error("Fill all fields");
      return;
    }
    console.log(eventForm, 'here is the data')
    const formData = {
        fullName: eventForm.fullName,
        email: eventForm.email,
        eventSource: eventForm.eventSource,
        eventUserType: eventForm.eventUserType,
        phoneNumber: eventForm.phoneNumber,
        challenges: eventForm.challenges,
        company: eventForm.company,
        designation: eventForm.designation,
        otherEventSource: eventForm.otherEventSource,
        onAnyPlatform: eventForm.onAnyPlatform,
        otherEventUserType: eventForm.otherEventUserType,
        otherPlatform: eventForm.otherPlatform,
        mailingList: eventForm.mailingList
    }
    const {data, error} = await AddEventApi(formData);
    if(data) {
        console.log(data, 'here is the event form data')
        toast.success("Form submitted Successfully");
        dispatch(SetShowForm(false));
        setEventForm({
            fullName: "",
            email: "",
            eventSource: 0,
            eventUserType: 0,
            phoneNumber: "",
            challenges: "",
            company: "",
            designation: "",
            otherEventSource: "",
            onAnyPlatform: false,
            otherEventUserType: "",
            otherPlatform: "",
            mailingList: false
        })
    }
    if(error) {
        console.log(error, 'here is the error message')
        toast.error('Error submitting form');
    }
  };

  return (
    <section
      style={{ alignItems: "center", justifyContent: "center", padding: 10 }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "left",
          justifyContent: "space-between",
          marginTop: 30,
        }}
      >
        <div style={{alignSelf: "center", justifyContent: "center", marginLeft: '27%'}}>
          <b style={{ fontSize: 20, color: "#0D600D" }}>
            Let's Talk Real Estate Registration Form
          </b>
        </div>
        <div className={styles.clickableelement}>
          <div className="icon-cancel" onClick={closeModal}></div>
        </div>
      </div>
      <div style={{ alignSelf: "center", justifyContent: "center" }}>
        <div>
          <p style={{ textAlign: "center", marginTop: 4 }}>
            Join us for an Exclusive Real Estate Gathering
          </p>
        </div>
      </div>
      <div>
        <p style={{ fontWeight: "bold" }}>Register Here</p>
        <form
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "20px", // Adds spacing between fields
          }}
        >
          {/* Full Name */}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label style={{ fontSize: "13px" }}>Full Name</label>
            <input
              type="text"
              name="fullName"
              onChange={handleChange}
              value={eventForm.fullName}
              style={{ height: 49, borderRadius: 8 }}
            />
          </div>

          {/* Email */}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label style={{ fontSize: "13px" }}>Email Address</label>
            <input
              type="text"
              name="email"
              value={eventForm.email}
              onChange={handleChange}
              style={{ height: 49, borderRadius: 8 }}
            />
          </div>

          {/* Phone Number */}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label style={{ fontSize: "13px" }}>Phone Number</label>
            <input
              type="text"
              name="phoneNumber"
              value={eventForm.phoneNumber}
              onChange={handleChange}
              style={{ height: 49, borderRadius: 8 }}
            />
          </div>

          {/* Organization */}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label style={{ fontSize: "13px" }}>
              Organization/Company Name
            </label>
            <input
              type="text"
              name="company"
              value={eventForm.company}
              onChange={handleChange}
              style={{ height: 49, borderRadius: 8 }}
            />
          </div>

          {/* Designation */}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label style={{ fontSize: "13px" }}>Designation</label>
            <input
              type="text"
              name="designation"
              value={eventForm.designation}
              onChange={handleChange}
              style={{ height: 49, borderRadius: 8 }}
            />
          </div>

          {/* Are you a */}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label style={{ fontSize: "13px" }}>Are you a:</label>
            <select
              name="eventUserType"
              value={eventForm.eventUserType}
              onChange={handleChange}
              style={{ height: 49, borderRadius: 8, borderColor: '#dce0de' }}
            >
              <option value="">Select One</option>
              <option value={EventType.RealEstateAgent}>
                Real Estate Agent
              </option>
              <option value={EventType.PropertyOwner}>Properties Owner</option>
              <option value={EventType.Landlord}>Landlord</option>
              <option value={EventType.Other}>Other (Specify)</option>
            </select>
          </div>

          {/* Conditional Other field */}
          {eventForm.eventUserType === EventType.Other && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // gridColumn: "span 2",
              }}
            >
              <label style={{ fontSize: "14px" }}>Others (specify)</label>
              <input
                type="text"
                name="otherEventUserType"
                value={eventForm.otherEventUserType}
                onChange={handleChange}
                style={{ height: 49, borderRadius: 8 }}
              />
            </div>
          )}

          {/* How Did You Hear About This Event */}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label style={{ fontSize: "13px" }}>
              How Did You Hear About This Event
            </label>
            <select
              name="eventSource"
              value={eventForm.eventSource}
              onChange={handleChange}
              style={{ height: 49, borderRadius: 8, borderColor: '#dce0de' }}
            >
              <option value="">Select One</option>
              <option value={EventSource.Email}>Email</option>
              <option value={EventSource.SocialMedia}>Social Media</option>
              <option value={EventSource.Referral}>Referral</option>
              <option value={EventSource.Other}>Other(Specify)</option>
            </select>
          </div>

          {/* Conditional Other field */}
          {eventForm.eventSource === EventSource.Other && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // gridColumn: "span 2",
              }}
            >
              <label style={{ fontSize: "14px" }}>Others (specify)</label>
              <input
                type="text"
                name="otherEventSource"
                value={eventForm.otherEventSource}
                onChange={handleChange}
                style={{ height: 49, borderRadius: 8 }}
              />
            </div>
          )}

          {/* Other fields */}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label style={{ fontSize: "14px" }}>
              Do You Currently List Your Properties On Any Platform
            </label>
            <select
              name="onAnyPlatform"
              value={eventForm.onAnyPlatform ? "true" : "false"}
              onChange={handleChange}
              style={{ height: 49, borderRadius: 8, borderColor: '#dce0de'}}
            >
              <option value="">Select an Option</option>
              <option value={"true"}>Yes</option>
              <option value={"false"}>No</option>
            </select>
          </div>

          
          {/* Conditional Other field */}
          {eventForm.onAnyPlatform === true && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // gridColumn: "span 2",
              }}
            >
              <label style={{ fontSize: "14px" }}>Other Platform (specify)</label>
              <input
                type="text"
                name="otherPlatform"
                value={eventForm.otherPlatform}
                onChange={handleChange}
                style={{ height: 49, borderRadius: 8 }}
              />
            </div>
          )}

          {/* Additional full-width fields */}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label style={{ fontSize: "14px" }}>
              What Are The Key Challenges You Face In The Real Estate Sector?
            </label>
            <input
              type="text"
              name="challenges"
              value={eventForm.challenges}
              onChange={handleChange}
              style={{ height: 49, borderRadius: 8 }}
            />
          </div>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <label style={{ fontSize: "14px" }}>
          Would You Like To Be Added To Homebuddy's Mailing List For Updates And Opportunities ?
            </label>
            <select
              name="mailingList"
              value={eventForm.mailingList ? "true" : "false"}
              onChange={handleChange}
              style={{ height: 49, borderRadius: 8, borderColor: '#dce0de'}}
            >
              <option value="">Select an Option</option>
              <option value={"true"}>Yes</option>
              <option value={"false"}>No</option>
            </select>
          </div>

          {/* Submit Button */}
          <div style={{ gridColumn: "span 2", textAlign: "center" }}>
            <button
              type="button"
              style={{
                padding: "10px 20px",
                borderRadius: 8,
                background: "#0D600D",
                color: "#fff",
              }}
              onClick={()=>{HandleFormSubmission();
              }}
            >
              Register
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default EventForm;
